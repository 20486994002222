/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/ArticlePage";
import resolveImage from "@dataResolvers/resolveImage";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ArticleQuery($uri: [String]) {
    craft {
      entry(section: "articles", uri: $uri) {
        ... on Craft_articles_article_Entry {
          slug
          uid
          type: typeHandle
          # overview
          image: image0 {
            ...ImageFragment
          }
          date: postDate
          authors: person0 {
            title
          }
          topics {
            title
            uid
          }
          # content
          contentBuilder {
            ...ContentBuilderFragment
          }
          # meta
          title
          url
          theme
          metaTitle
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
      }
      entries(section: "articles", limit: 3) {
        ... on Craft_articles_article_Entry {
          uid
          date: postDate
          authors: person0 {
            title
          }
          type: typeHandle
          title
          url
          topics {
            title
            uid
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    slug,
    type,
    // content
    date,
    image,
    contentBuilder,
    // meta
    url,
    title,
    theme,
    metaTitle,
    metaDescription,
    metaImage,
    authors,
  } = entry;

  const articles = entries;

  return {
    slug,
    type,
    hero: {
      title,
      date,
      image: resolveImage(image || metaImage),
      type,
      authors,
    },
    contentBuilder: resolveContentBuilder(contentBuilder),
    articles,
    theme,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ArticlesArticleQuery"
    {...props}
  />
);

export default Page;
