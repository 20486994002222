import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const ArticleBody = loadable(() => import("@organisms/ArticleBody"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const ArticlePage = ({ data }) => {
  const { slug, hero, contentBuilder, articles, type, theme } = data;
  return (
    <PageContainer overflow>
      <ArticleBody
        hero={hero}
        contentBuilder={contentBuilder}
        articles={articles}
        type={type}
        theme={theme}
        slug={slug}
      />
      <NewsletterSignup />
    </PageContainer>
  );
};

export default ArticlePage;
