import resolveImage from "./resolveImage";

const resolveContentBuilder = blocks =>
  blocks?.map(block => {
    const { type, uid } = block;
    switch (type) {
      case "button":
        return {
          type,
          uid,
          url: block.link?.url,
          text: block.link?.text,
          style: block.style,
        };
      case "copy":
        return {
          type,
          uid,
          copy: block.copy,
        };
      case "cta":
        return {
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
        };
      case "form":
        return {
          type,
          uid,
          color: block.color,
          heading: block.heading,
          descriptor: block.descriptor,
          formid: block.formid,
          download: block.download?.[0]?.url,
        };
      case "images":
        return {
          type,
          uid,
          images: block.images,
          caption: block.caption,
        };
      case "lead":
        return {
          type,
          uid,
          copy: block.copy,
        };
      case "testimonial":
        return {
          type,
          uid,
          testimonial: {
            ...block.testimonial?.[0],
            image: resolveImage(block.testimonial?.[0]?.image),
          },
          color: "teal",
        };
      case "video":
        return {
          type,
          uid,
          url: block.url,
          caption: block.caption,
        };
      default:
        return block;
    }
  });

export default resolveContentBuilder;
